import React, { useState, useEffect } from 'react';
import { getImages, deleteImage } from 'services/afric.service';

export const ManageContent = () => {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(9);
	const [pageData, setPageData] = useState<any>({});
	const [images, setImages] = useState<any[]>([]);

	const abortController = new AbortController();

	const loadAllImages = (page: number, limit: number) => {
		getImages(abortController.signal, page, limit).then((response: any) => {
			setImages(response.data?.results);
			setPageData(response.data?.next);
		});
	};

	const handleDeleteImage = (id: string) => {
		let confirm = window.confirm('Are you sure you want to delete this Item?');

		if (confirm) {
			deleteImage(abortController.signal, id).then((response: any) => {
				if (response.success) {
					loadAllImages(page, limit);
				} else {
					window.alert('Unable to delete image');
				}
			});
		}
	};

	useEffect(() => {
		loadAllImages(page, limit);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, limit]);

	return (
		<div className='container p-3'>
			<div className='row justify-content-center'>
				<div className='card card-dash'>
					<h1 className='card-header'>Content</h1>
				</div>
				<div className='col-12'>
					<div className='row'>
						{images.map((image) => {
							return (
								<div className='col-6 col-md-4'>
									<div className='card'>
										<div className='card-body'>
											<div className='d-flex flex-row justify-content-between mb-2'>
												<p className='m-0'>{image.name}</p>

												<a
													href='#!'
													className='text-danger'
													onClick={() => handleDeleteImage(image._id)}
												>
													<i className='fe fe-trash-2'></i>
												</a>
											</div>

											<p className='m-0 mb-1'>
												<a href='#!' className='text-muted'>
													{image.vendorId || image.userId}
												</a>
											</p>

											{/* <a href='#!' className='avatar avatar-lg w-100 h-100'> */}
											<img
												src={image.imageUrl}
												alt='...'
												className='avatar-img rounded'
											/>
											{/* </a> */}
										</div>
									</div>
								</div>
							);
						})}
					</div>

					<div className='col-12'>
						<div className='d-flex flex-row justify-content-between'>
							{page > 1 && (
								<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-0 pe-4 border-end'
											href='#!'
											onClick={() => {
												setPage(page - 1);
												setLimit(limit);
											}}
										>
											<i className='fe fe-arrow-left me-1'></i> Prev
										</a>
									</li>
								</ul>
							)}

							<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

							{images.length === limit && (
								<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-4 pe-0 border-start'
											href='#!'
											onClick={() => {
												setPage(pageData.page);
												setLimit(limit);
											}}
										>
											Next <i className='fe fe-arrow-right ms-1'></i>
										</a>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
