import { initializeApp } from 'firebase/app';

export const app = initializeApp({
	apiKey: 'AIzaSyC-QU7LDgOJIwThm7cWgy9ErmZwTPonV-8',
	authDomain: 'africweddings-c366d.firebaseapp.com',
	projectId: 'africweddings-c366d',
	storageBucket: 'africweddings-c366d.appspot.com',
	messagingSenderId: '1032232844030',
	appId: '1:1032232844030:web:1547e78f32140c127243af',
});
