import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

import { app } from '../../utils/firebase.util';

export const ChatManagement = () => {
	const [chats, setChats] = useState<any[]>([]);

	const db = getFirestore(app);

	// const getFromName = async (id: string) => {
	// 	const ref = doc(db, 'users', id);
	// 	const snap = await getDoc(ref);

	// 	if (snap.exists()) {
	// 		return snap.data()['fullName'];
	// 	} else {
	// 		return 'Unknown';
	// 	}
	// };

	useEffect(() => {
		const getMessages = async () => {
			const snapshot = await getDocs(collection(db, 'messages'));

			const messages = snapshot.docs.map((message) => {
				const data = {
					id: message.id,
					createdAt: message.data()['createdAt'].seconds * 1000,
				};

				const members = {
					from: message.data()['members'][0],
					to: message.data()['members'][1],
				};

				return { data, members };
			});

			setChats(messages);
		};

		getMessages();
	}, [db]);

	const [page, setPage] = useState(1);

	const navigate = useNavigate();

	const limit = 10;

	const lastIndex = page * limit;
	const firstIndex = lastIndex - limit;

	const currentChats = chats.slice(firstIndex, lastIndex);

	const nPages = Math.ceil(chats.length / limit);

	return (
		<div className='container p-3'>
			<div className='card card-dash'>
				<div className='card-header'>
					<h2 className='card-header-title'>Chats</h2>
				</div>
			</div>
			<div className='card'>
				<div className='card-body'>
					<div className='table-responsive mb-0'>
						<table className='table table-sm table-nowrap card-table'>
							<thead>
								<tr>
									<th>Date</th>
									<th>From</th>
									<th>To</th>
									<th>View Chat</th>
								</tr>
							</thead>
							<tbody>
								{currentChats &&
									currentChats.map((chat) => (
										<tr>
											<td>
												{format(new Date(chat.data?.createdAt), 'MM/dd/yyyy')}
											</td>
											<td>
												<span className='text-primary'>
													{chat.members.from}
												</span>
											</td>
											<td>
												<span className='text-primary'>{chat.members.to}</span>
											</td>
											<td className='text-center'>
												<button
													className='btn btn-white bg-color-brand text-white w-75'
													onClick={() =>
														navigate(`/chat-management/${chat.data.id}`)
													}
												>
													View
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<div className='col-12 mt-3'>
						<div className='d-flex flex-row justify-content-between'>
							{page > 1 && (
								<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-0 pe-4 border-end'
											href='#!'
											onClick={() => {
												if (page !== 1) setPage(page - 1);
											}}
										>
											<i className='fe fe-arrow-left me-1'></i> Prev
										</a>
									</li>
								</ul>
							)}

							<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

							{currentChats.length === limit && (
								<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-4 pe-0 border-start'
											href='#!'
											onClick={() => {
												if (page !== nPages) setPage(page + 1);
											}}
										>
											Next <i className='fe fe-arrow-right ms-1'></i>
										</a>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
