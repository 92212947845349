import { useSessionStorage } from 'hooks';
import { AuthRequest, AuthResponse } from 'interfaces/auth.interface';
import { BaseApiResponse } from 'interfaces/base.interface';
import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { authenticate } from 'services/afric.service';
import { addCookie } from 'utils/cookies.util';
import { addClass, removeClass } from 'utils/dom.util';

import logo from '../../assets/afric-logo.png';

const SignIn = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [payload, setInput] = useState<AuthRequest>({
		email: '',
		password: '',
	});

	const [_, setUser] = useSessionStorage('user', {});

	console.log(_);

	const abortController = new AbortController();

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		setInput({ ...payload, [e.currentTarget.name]: e.currentTarget.value });
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		authenticate(payload, abortController.signal)
			.then((authRes: BaseApiResponse<AuthResponse>) => {
				// Add Refresh cookie to Localstorage
				setUser(authRes?.data?.userDetails);
				addCookie(
					'1Q_SPA',
					authRes?.data?.token?.refresh?.token,
					new Date(authRes?.data?.token?.refresh?.expires).getTime()
				);

				setTimeout(() => {
					navigate('/dashboard/', { replace: true });
				}, 2500);
			})
			.catch((err) => {
				console.log(err);
				alert('Error while login in');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		const bodyEl = document.getElementsByTagName('body')[0] as HTMLBodyElement;
		addClass(bodyEl, [
			'd-flex',
			'align-items-center',
			'bg-auth',
			'border-top',
			'border-top-2',
			'border-white',
			'bg-color-brand',
		]);

		return () => {
			removeClass(bodyEl, [
				'd-flex',
				'align-items-center',
				'bg-auth',
				'border-top',
				'border-top-2',
				'border-white',
				'bg-color-brand',
			]);
		};
	}, []);

	useEffect(() => {
		const forms = document.querySelectorAll('.needs-validation');

		// Loop over them and prevent submission
		Array.from(forms).forEach((form: any) => {
			form.addEventListener(
				'submit',
				(event: React.FormEvent<HTMLFormElement>) => {
					if (!form.checkValidity()) {
						event.preventDefault();
						event.stopPropagation();
					}

					form.classList.add('was-validated');
				},
				false
			);
		});

		// TODO: Add clean up function
	}, []);

	return (
		<div className='container text-white'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-5 col-xl-4 my-5'>
					<img src={logo} alt='logo' className='my-4' />

					<h1 className='display-4 text-center mb-3'>Sign in</h1>

					<p className='text-white text-center mb-5'>
						Welcome to AfricWeddings Admin dashboard.
					</p>

					<form onSubmit={onSubmit} noValidate className='needs-validation'>
						<div className='form-group has-validation'>
							<label className='form-label'>Email Address</label>
							<input
								type='email'
								className='form-control'
								placeholder='name@address.com'
								onChange={handleChange}
								name='email'
								required
							/>
							<div className='invalid-feedback'>
								Please enter a valid email address
							</div>
						</div>

						<div className='form-group'>
							<div className='row'>
								<div className='col'>
									<label className='form-label'>Password</label>
								</div>
								<div className='col-auto'>
									<a
										href='password-reset-cover.html'
										className='form-text small text-muted'
									>
										Forgot password?
									</a>
								</div>
							</div>

							<div className='input-group input-group-merge has-validation'>
								<input
									className='form-control'
									type='password'
									placeholder='Enter your password'
									name='password'
									onChange={handleChange}
									required
								/>
								<span className='input-group-text'>
									<i className='fe fe-eye'></i>
								</span>
								<div className='invalid-feedback'>Password is required</div>
							</div>
						</div>

						<button
							type='submit'
							className='btn btn-lg w-100 btn-white mb-3'
							disabled={loading}
						>
							{loading ? (
								<div
									className='spinner-border spinner-border-sm'
									role='status'
								></div>
							) : (
								<span>Sign in</span>
							)}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
