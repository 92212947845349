import React from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { Provider } from "react-redux";
// import { store } from "./store";
// import { PersistGate } from "redux-persist/integration/react";
// import { getPersistor } from "@rematch/persist";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "routes";

import './index.css'

const root = ReactDOM.createRoot(
  // document.getElementById("root") as HTMLElement
  document.getElementsByTagName('body')[0] as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <PersistGate persistor={getPersistor()}>
      <Provider store={store}>
        <App />
      </Provider>
    </PersistGate> */}
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
