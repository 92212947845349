import { useSessionStorage } from "../hooks";
// import { getProfile } from '';
import {
  fetchCookie,
  // , removeCookie
} from "../utils/cookies.util";
import { isEmpty } from "../utils/helpers";
import { useEffect, useMemo, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { DashboardLayout } from "components/layouts/DashboardLayout";

type Props = {
  roleOnly?: string;
};
export const PrivateRoutes = ({ roleOnly }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useSessionStorage("user", {});
  const location = useLocation();
  const auth = useMemo(() => {
    return { isAuthenticated: fetchCookie("1Q_SPA") ? true : false };
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    if (auth.isAuthenticated && isEmpty(user)) {
      // getProfile(abortController.signal)
      // 	.then((re: any) => {
      // 		console.log(re);
      // 		setUser(re['data']);
      // 	})
      // 	.catch((error: any) => {
      // 		console.log('Error: ', error);
      // 		removeCookie('1Q_SPA');
      // 		auth.isAuthenticated = false;
      // 	})
      // 	.finally(() => {
      // 		setTimeout(() => {
      // 			setLoading(false);
      // 		}, 3000);
      // 	});
    } else {
      setLoading(false);
    }

    return () => {
      if (!abortController.signal.aborted) abortController.abort();
    };
  }, [user, setUser, auth]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return auth.isAuthenticated && !loading ? (
    !roleOnly ? (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ) : user && user?.role && user?.role?.includes(roleOnly) ? (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ) : (
      <Navigate
        to="/unauthorized/"
        state={{ authenticated: true, from: location }}
        replace
      />
    )
  ) : (
    <Navigate
      to="/sign-in/"
      state={{ authenticated: false, from: location }}
      replace
    />
  );
};
