import React, { useState } from 'react';

import { sendNotificationAll } from 'services/afric.service';

export const Notifications = () => {
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');

	const abortController = new AbortController();

	const handleSubmit = (e: any) => {
		e.preventDefault();

		const data = { title: title, message: message };

		sendNotificationAll(abortController.signal, data).then((res: any) => {
			window.alert(res.message);
		});
	};

	return (
		<div className='container mt-5'>
			<div className='card card-dash'>
				<h2 className='card-header'>Notifications</h2>
			</div>
			<div className='card'>
				<div className='card-header'>
					<h2 className='card-header-title'>New Notification</h2>
				</div>

				<div className='card-body'>
					<div className='row align-items-center'>
						<div className='col'>
							<div className='form-group'>
								<label className='form-label fw-bold'>Title</label>

								<input
									type='email'
									className='form-control form-control-flush form-control-auto'
									id='exampleInputEmail1'
									placeholder='Enter Notification Title'
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								/>
							</div>

							<div className='form-group my-2'>
								<label className='form-label fw-bold'>Message</label>

								<textarea
									className='form-control form-control-flush form-control-auto'
									data-autosize
									rows={7}
									placeholder='Enter Notification Content'
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								></textarea>
							</div>
						</div>
					</div>
				</div>
				<div className='card-footer'>
					<div className='col-auto'>
						<button className='btn btn-white text-white bg-color-brand w-50' onClick={handleSubmit}>
							Send Notification
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
