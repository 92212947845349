import React, { useState, useEffect, FormEvent } from 'react';
import {
	getAllVendorCategories,
	addVendorCategory,
} from 'services/afric.service';
import {
	// AiFillEdit,
	AiFillDelete,
} from 'react-icons/ai';
import { BsUpload } from 'react-icons/bs';
import { DeleteModal } from 'components/DeleteModal';

type vendorCategoryType = {
	imageUrl: string;
	name: string;
	_id: string;
};

export const VendorCategories = () => {
	const [inputs, setInput] = useState({
		name: '',
	});
	const [imageUpload, setImageUpload] = useState<File | null>();
	const [vendorsCategories, setVendorsCategories] = useState<
		vendorCategoryType[] | null
	>();
	const [loading, setLoading] = useState<string>();
	const [toDelete, setDelete] = useState({ id: '', show: false });

	const abortController = new AbortController();
	const load = () => {
		getAllVendorCategories(abortController.signal).then((response: any) => {
			setVendorsCategories(response.data);
		});
	};
	const add = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.log(imageUpload);
		const formData = new FormData();
		formData.append('name', inputs.name);
		if (imageUpload) formData.append('image', imageUpload);
		setLoading('loading');
		addVendorCategory(formData, abortController.signal)
			.then(() => {
				load();
			})
			.finally(() => {
				setLoading('idle');
			});
	};
	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		setInput({ ...inputs, [e.currentTarget.name]: e.currentTarget.value });
	};

	return (
		<div className='container mt-4'>
			<div className='card card-dash'>
				<div className='card-header'>
					<h4 className='card-header-title'>Vendor Categories</h4>
					{/* <p>Keep track of all the vendor categories sent on AfricWeddings</p> */}

					<div className='dropdown'>
						<button
							className='btn btn-light'
							type='button'
							id='inviteMember'
							data-bs-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'
						>
							New Category
						</button>
						<form
							onSubmit={add}
							className='dropdown-menu dropdown-menu-end dropdown-menu-card'
							aria-labelledby='inviteMember'
						>
							<div className='card-header'>
								<h4 className='card-header-title'>Create a category</h4>
							</div>
							<div className='card-header'>
								<div className='row g-0 align-items-center'>
									<div className='col-3'>
										<span className='badge bg-primary-soft'>
											{/* <BsUpload />{" "} */}
											<label htmlFor='file-input'>
												{!imageUpload && <BsUpload />}
												{imageUpload && (
													<img
														style={{
															width: 50,
															height: 50,
															objectFit: 'cover',
														}}
														src={URL.createObjectURL(imageUpload)}
														alt='profileImage'
													/>
												)}
											</label>
											<input
												id='file-input'
												type='file'
												accept='.png,.jpg,.jpeg'
												onChange={(e) =>
													e.target.files && setImageUpload(e?.target?.files[0])
												}
											/>
										</span>
									</div>
								</div>
							</div>
							<div className='card-header'>
								<div className='row g-0 align-items-center'>
									<div className='col-3'>
										<label className='mb-0' htmlFor='inviteMemberName'>
											Name
										</label>
									</div>
									<div className='col'>
										<input
											onChange={handleChange}
											name={'name'}
											className='form-control form-control-flush'
											id='inviteMemberName'
											type='text'
											placeholder='category name'
										/>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<button
									disabled={loading === 'loading'}
									className='btn w-100 btn-white bg-color-brand text-white'
									type='submit'
								>
									{loading === 'loading' ? (
										<div
											className='spinner-border spinner-border-sm'
											role='status'
										></div>
									) : (
										<span>Create Category</span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className='card m-3'>
				<div className='card-body' style={{ paddingLeft: '55px' }}>
					<div className='row my-n3 gx-7'>
						{vendorsCategories &&
							vendorsCategories?.map((category: vendorCategoryType) => (
								<div className='col-12 col-md-6  ms-n2 d-flex justify-content-between'>
									<div className='d-flex align-items-center'>
										<div className='me-4'>
											<a
												href='profile-posts.html'
												className='avatar avatar-xl card-avatar '
											>
												<img
													src={category.imageUrl}
													className='avatar-img rounded-circle'
													alt='...'
												/>
											</a>
										</div>
										<div>
											<h4 className='text-start'>
												<div>{category.name}</div>
											</h4>
										</div>
									</div>
									{/* <AiFillEdit /> */}
									<div
										className='d-flex flex-column justify-content-center align-items-center'
										onClick={() =>
											setDelete({
												...toDelete,
												show: true,
												id: category._id,
											})
										}
									>
										<AiFillDelete />
									</div>
									{/* </div> */}
								</div>
							))}
					</div>
				</div>
			</div>
			<DeleteModal
				toDelete={toDelete}
				handleClose={() => setDelete({ ...toDelete, id: '', show: false })}
				load={load}
			/>
		</div>
	);
};
