/* eslint-disable jsx-a11y/anchor-is-valid */
import { BlockModal } from 'components/BlockModal';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllVendors } from 'services/afric.service';

type getUserType = {
	profileImage: string;
	fullName: string;
	email: string;
	phone: string;
	_id: string;
	deactivation: string;
};

const BLOCK = 'block';
const UNBLOCK = 'unblock';

interface getBalanceType {
	earningsTotal?: number;
	unreleasedPayments?: number;
	currentWalletBalance?: number;
}

export const Vendors = () => {
	const [vendors, setVendors] = useState<any>([]);
	const [getBalance, setGetBalance] = useState<getBalanceType>({});
	const [searchTerm, setSearchTerm] = useState('');
	const [searchData, setSearchData] = useState<any>([]);
	const [toBlock, setBlock] = useState({ id: '', show: false, type: BLOCK });

	const abortController = new AbortController();
	const loadAllVendors = () => {
		getAllVendors(abortController.signal).then((response: any) => {
			setGetBalance({
				earningsTotal: response.data?.results?.earningsTotal,
				unreleasedPayments: response.data?.results?.unreleasedPayments,
				currentWalletBalance: response.data?.results?.currentWalletBalance,
			});

			setVendors(response.data?.results?.users);
		});
	};

	useEffect(() => {
		loadAllVendors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (term: string) => {
		setSearchTerm(term);

		if (searchTerm !== '') {
			const filteredData = vendors.filter((x: any) =>
				x._id.toLowerCase().includes(term.toLowerCase())
			);

			setSearchData(filteredData);
		} else {
			setSearchData(vendors);
		}
	};

	return (
		<div className='container-fluid'>
			<div className='row justify-content-center'>
				<div className='col-12 mt-4'>
					<div className='row align-items-center'>
						<div className='col card card-dash'>
							<div className='card-body'>
								<h6 className='header-pretitle text-white'>Overview</h6>
								<h1 className='header-title text-truncate'>Vendors</h1>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-12 col-lg-6 col-xl'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-muted mb-2'>
												All Time Earnings
											</h6>
											<span className='h2 mb-0'>
												&#8358;{getBalance?.earningsTotal?.toLocaleString()}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-naira-sign text-muted mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-12 col-lg-6 col-xl'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-muted mb-2'>
												Current Wallet Balance
											</h6>
											<span className='h2 mb-0'>
												&#8358;
												{getBalance?.currentWalletBalance?.toLocaleString()}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase text-muted mb-0'></span>
										</div>
									</div>{' '}
								</div>
							</div>
						</div>
						<div className='col-12 col-lg-6 col-xl'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-muted mb-2'>
												Unreleased Payments
											</h6>
											<span className='h2 mb-0'>
												&#8358;
												{getBalance?.unreleasedPayments?.toLocaleString()}
											</span>
										</div>
										<div className='col-auto'>
											<div className='chart chart-sparkline'>
												<canvas
													className='chart-canvas'
													id='sparklineChart'
													width='75'
													height='35'
												></canvas>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='card'>
						<div className='card-body pb-1'>
							<div className='form-group'>
								<input
									className='form-control'
									type='text'
									placeholder='Search by ID'
									onChange={(e) => handleSearch(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<div className='tab-content'>
						<div
							className='tab-pane fade show active'
							id='contactsListPane'
							role='tabpanel'
							aria-labelledby='contactsListTab'
						>
							<div
								className='card'
								data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
								id='contactsList'
							>
								<div className='table-responsive'>
									<table className='table table-sm table-hover table-nowrap card-table'>
										<thead>
											<tr>
												<th>
													<div className='form-check mb-n2'>
														<input
															title='title'
															placeholder='input'
															className='form-check-input list-checkbox-all'
															id='listCheckboxAll'
															type='checkbox'
														/>
														<label className='form-check-label'></label>
													</div>
												</th>
												<th>Name</th>

												<th>Email</th>
												<th>Phone</th>
												<th>ID</th>
												<th>Actions</th>
											</tr>
										</thead>
										{searchTerm.length > 1
											? searchData?.map((category: getUserType) => (
													<tbody className='list fs-base'>
														<tr
															className={`${
																category.deactivation === 'blocked'
																	? 'bg-warning'
																	: ''
															}`}
														>
															<td>
																<div className='form-check'>
																	<input
																		title='title'
																		placeholder='input'
																		className='form-check-input list-checkbox'
																		id='listCheckboxOne'
																		type='checkbox'
																	/>
																	<label
																		className='form-check-label'
																		htmlFor='listCheckboxOne'
																	></label>
																</div>
															</td>
															<td>
																<div className='avatar avatar-lg align-middle me-2'>
																	<img
																		className='avatar-img rounded-circle'
																		src={
																			category.profileImage
																				? category.profileImage
																				: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
																		}
																		alt='...'
																	/>
																</div>
																<Link
																	to={`/vendor-profile/${category._id}`}
																	className='text-decoration-none'
																>
																	<a className='item-name text-reset' href=''>
																		{category.fullName}
																	</a>
																</Link>
															</td>
															<td>
																<a
																	className='item-email text-reset'
																	href={`mailto:${category.email}`}
																>
																	{category.email}
																</a>
															</td>
															<td>
																<a
																	className='item-phone text-reset'
																	href={`tel:${category.phone}`}
																>
																	{category.phone}
																</a>
															</td>
															<td>
																<a className='text-link' href='#'>
																	{category._id}
																</a>
															</td>
															<td className='text-end'>
																<div className='dropdown'>
																	<a
																		className='dropdown-ellipses dropdown-toggle'
																		href='#'
																		role='button'
																		data-bs-toggle='dropdown'
																		aria-haspopup='true'
																		aria-expanded='false'
																	>
																		<i className='fe fe-more-vertical'></i>
																	</a>
																	<div
																		className='dropdown-menu dropdown-menu-end'
																		onClick={() =>
																			setBlock({
																				...toBlock,
																				show: true,
																				id: category._id,
																				type:
																					category?.deactivation === 'blocked'
																						? UNBLOCK
																						: BLOCK,
																			})
																		}
																	>
																		<a href='#!' className='dropdown-item'>
																			{category.deactivation === 'blocked'
																				? 'Unblock Vendor'
																				: 'Block Vendor'}
																		</a>
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
											  ))
											: vendors.map((category: getUserType) => (
													<tbody className='list fs-base'>
														<tr
															className={`${
																category.deactivation === 'blocked'
																	? 'bg-warning'
																	: ''
															}`}
														>
															<td>
																<div className='form-check'>
																	<input
																		title='title'
																		placeholder='input'
																		className='form-check-input list-checkbox'
																		id='listCheckboxOne'
																		type='checkbox'
																	/>
																	<label
																		className='form-check-label'
																		htmlFor='listCheckboxOne'
																	></label>
																</div>
															</td>
															<td>
																<div className='avatar avatar-lg align-middle me-2'>
																	<img
																		className='avatar-img rounded-circle'
																		src={
																			category.profileImage
																				? category.profileImage
																				: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
																		}
																		alt='...'
																	/>
																</div>
																<Link
																	to={`/vendor-profile/${category._id}`}
																	className='text-decoration-none'
																>
																	<a className='item-name text-reset' href=''>
																		{category.fullName}
																	</a>
																</Link>
															</td>
															<td>
																<a
																	className='item-email text-reset'
																	href={`mailto:${category.email}`}
																>
																	{category.email}
																</a>
															</td>
															<td>
																<a
																	className='item-phone text-reset'
																	href={`tel:${category.phone}`}
																>
																	{category.phone}
																</a>
															</td>
															<td>
																<a className='text-link' href='#'>
																	{category._id}
																</a>
															</td>
															<td className='text-end'>
																<div className='dropdown'>
																	<a
																		className='dropdown-ellipses dropdown-toggle'
																		href='#'
																		role='button'
																		data-bs-toggle='dropdown'
																		aria-haspopup='true'
																		aria-expanded='false'
																	>
																		<i className='fe fe-more-vertical'></i>
																	</a>
																	<div
																		className='dropdown-menu dropdown-menu-end'
																		onClick={() =>
																			setBlock({
																				...toBlock,
																				show: true,
																				id: category._id,
																				type:
																					category?.deactivation === 'blocked'
																						? UNBLOCK
																						: BLOCK,
																			})
																		}
																	>
																		<a href='#!' className='dropdown-item'>
																			{category.deactivation === 'blocked'
																				? 'Unblock Vendor'
																				: 'Block Vendor'}
																		</a>
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
											  ))}
									</table>
								</div>
								<div className='card-footer d-flex justify-content-between'>
									<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
										<li className='page-item'>
											<a className='page-link ps-0 pe-4 border-end' href='#'>
												<i className='fe fe-arrow-left me-1'></i> Prev
											</a>
										</li>
									</ul>

									<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

									<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
										<li className='page-item'>
											<a className='page-link ps-4 pe-0 border-start' href='#'>
												Next <i className='fe fe-arrow-right ms-1'></i>
											</a>
										</li>
									</ul>

									<div
										className='list-alert alert alert-dark alert-dismissible border fade'
										role='alert'
									>
										<div className='row align-items-center'>
											<div className='col'>
												<div className='form-check'>
													<input
														title='title'
														placeholder='input'
														className='form-check-input'
														id='listAlertCheckbox'
														type='checkbox'
														checked
														disabled
													/>
													<label
														className='form-check-label text-white'
														htmlFor='listAlertCheckbox'
													>
														<span className='list-alert-count'>0</span> deal(s)
													</label>
												</div>
											</div>
											<div className='col-auto me-n3'>
												<button className='btn btn-sm btn-white-20'>
													Edit
												</button>

												<button className='btn btn-sm btn-white-20'>
													Delete
												</button>
											</div>
										</div>

										<button
											type='button'
											className='list-alert-close btn-close'
											aria-label='Close'
										></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BlockModal
				handleClose={() =>
					setBlock({ ...toBlock, id: '', show: false, type: BLOCK })
				}
				load={loadAllVendors}
				toBlock={toBlock}
			/>
		</div>
	);
};
