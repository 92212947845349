/* eslint-disable jsx-a11y/anchor-is-valid */
import { BlockModal } from 'components/BlockModal';
import React, { useState, useEffect } from 'react';
import { getUsers } from 'services/afric.service';
import { Link } from 'react-router-dom';

type getUserType = {
	profileImage: string;
	fullName: string;
	email: string;
	phone: string;
	_id: string;
	deactivation: string;
};

const BLOCK = 'block';
const UNBLOCK = 'unblock';

export const Customer = () => {
	const [customers, setCustomers] = useState<any>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchData, setSearchData] = useState<any>([]);
	const [toBlock, setBlock] = useState({ id: '', show: false, type: BLOCK });

	const abortController = new AbortController();

	const load = () => {
		getUsers(abortController.signal).then((response: any) => {
			setCustomers(response.data?.results);
		});
	};

	useEffect(() => {
		load();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (term: string) => {
		setSearchTerm(term);

		if (searchTerm !== '') {
			const filteredData = customers.filter((x: any) =>
				x._id.toLowerCase().includes(term.toLowerCase())
			);

			setSearchData(filteredData);
		} else {
			setSearchData(customers);
		}
	};

	return (
		<div className='container-fluid'>
			<div className='row justify-content-center'>
				<div className='col-12'>
					<div className='row align-items-center mt-4'>
						<div className='col card card-dash'>
							<div className='card-body'>
								<h6 className='header-pretitle text-white'>Overview</h6>
								<h1 className='header-title text-truncate'>Customers</h1>
							</div>
						</div>
					</div>

					<div className='card'>
						<div className='card-body pb-1'>
							<div className='form-group'>
								<input
									className='form-control'
									type='text'
									placeholder='Search by ID'
									onChange={(e) => handleSearch(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className='tab-content'>
						<div
							className='tab-pane fade show active'
							id='contactsListPane'
							role='tabpanel'
							aria-labelledby='contactsListTab'
						>
							<div
								className='card'
								data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
								id='contactsList'
							>
								<div className='table-responsive'>
									<table className='table table-sm table-hover table-nowrap card-table'>
										<thead>
											<tr>
												<th>Name</th>
												<th>Email</th>
												<th>Phone</th>
												<th>ID</th>
												<th>Actions</th>
											</tr>
										</thead>

										{searchTerm.length > 1
											? searchData?.map((category: getUserType) => (
													<tbody className='list fs-base'>
														<tr
															className={`${
																category.deactivation === 'blocked'
																	? 'bg-warning'
																	: ''
															}`}
														>
															<td>
																<div className='avatar avatar-xs align-middle me-2'>
																	<img
																		className='avatar-img rounded-circle '
																		src={
																			category.profileImage
																				? category.profileImage
																				: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
																		}
																		alt='...'
																	/>
																</div>
																<a className='item-name text-reset'>
																	<Link to={`/customer/${category._id}`}>
																		{category.fullName}
																	</Link>
																</a>
															</td>
															<td>
																<a
																	className='item-email text-reset'
																	href={`mailto:${category.email}`}
																>
																	{category.email}
																</a>
															</td>
															<td>
																<a
																	className='item-phone text-reset'
																	href={`tel:${category.phone}`}
																>
																	{category.phone}
																</a>
															</td>
															<td>
																<a className='text-link' href='#'>
																	{category._id}
																</a>
															</td>
															<td className='text-end'>
																<div className='dropdown'>
																	<a
																		className='dropdown-ellipses dropdown-toggle'
																		href='#'
																		role='button'
																		data-bs-toggle='dropdown'
																		aria-haspopup='true'
																		aria-expanded='false'
																	>
																		<i className='fe fe-more-vertical'></i>
																	</a>
																	<div
																		className='dropdown-menu dropdown-menu-end'
																		onClick={() =>
																			setBlock({
																				...toBlock,
																				show: true,
																				id: category._id,
																				type:
																					category?.deactivation === 'blocked'
																						? UNBLOCK
																						: BLOCK,
																			})
																		}
																	>
																		<a href='#' className='dropdown-item'>
																			{category.deactivation === 'blocked'
																				? 'Unblock customer'
																				: 'Block Customer'}
																		</a>
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
											  ))
											: customers.map((category: getUserType) => (
													<tbody className='list fs-base'>
														<tr
															className={`${
																category.deactivation === 'blocked'
																	? 'bg-warning'
																	: ''
															}`}
														>
															<td>
																<div className='avatar avatar-xs align-middle me-2'>
																	<img
																		className='avatar-img rounded-circle '
																		src={
																			category.profileImage
																				? category.profileImage
																				: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
																		}
																		alt='...'
																	/>
																</div>
																<a className='item-name text-reset'>
																	<Link to={`/customer/${category._id}`}>
																		{category.fullName}
																	</Link>
																</a>
															</td>
															<td>
																<a
																	className='item-email text-reset'
																	href={`mailto:${category.email}`}
																>
																	{category.email}
																</a>
															</td>
															<td>
																<a
																	className='item-phone text-reset'
																	href={`tel:${category.phone}`}
																>
																	{category.phone}
																</a>
															</td>
															<td>
																<a className='text-link' href='#'>
																	{category._id}
																</a>
															</td>
															<td className='text-end'>
																<div className='dropdown'>
																	<a
																		className='dropdown-ellipses dropdown-toggle'
																		href='#'
																		role='button'
																		data-bs-toggle='dropdown'
																		aria-haspopup='true'
																		aria-expanded='false'
																	>
																		<i className='fe fe-more-vertical'></i>
																	</a>
																	<div
																		className='dropdown-menu dropdown-menu-end'
																		onClick={() =>
																			setBlock({
																				...toBlock,
																				show: true,
																				id: category._id,
																				type:
																					category?.deactivation === 'blocked'
																						? UNBLOCK
																						: BLOCK,
																			})
																		}
																	>
																		<a href='#' className='dropdown-item'>
																			{category.deactivation === 'blocked'
																				? 'Unblock customer'
																				: 'Block Customer'}
																		</a>
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
											  ))}
									</table>
								</div>
								<div className='card-footer d-flex justify-content-between'>
									<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
										<li className='page-item'>
											<a className='page-link ps-0 pe-4 border-end' href='#'>
												<i className='fe fe-arrow-left me-1'></i> Prev
											</a>
										</li>
									</ul>

									<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

									<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
										<li className='page-item'>
											<a className='page-link ps-4 pe-0 border-start' href='#'>
												Next <i className='fe fe-arrow-right ms-1'></i>
											</a>
										</li>
									</ul>

									<div
										className='list-alert alert alert-dark alert-dismissible border fade'
										role='alert'
									>
										<div className='row align-items-center'>
											<div className='col'>
												<div className='form-check'>
													<input
														title='title'
														placeholder='input'
														className='form-check-input'
														id='listAlertCheckbox'
														type='checkbox'
														checked
														disabled
													/>
													<label
														className='form-check-label text-white'
														htmlFor='listAlertCheckbox'
													>
														<span className='list-alert-count'>0</span> deal(s)
													</label>
												</div>
											</div>
											<div className='col-auto me-n3'>
												<button className='btn btn-sm btn-white-20'>
													Edit
												</button>

												<button className='btn btn-sm btn-white-20'>
													Delete
												</button>
											</div>
										</div>

										<button
											type='button'
											className='list-alert-close btn-close'
											aria-label='Close'
										></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BlockModal
				handleClose={() =>
					setBlock({ ...toBlock, id: '', show: false, type: BLOCK })
				}
				load={load}
				toBlock={toBlock}
			/>
		</div>
	);
};
