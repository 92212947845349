import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getVendorProfile } from 'services/afric.service';
import { format } from 'date-fns';

type getUserType = {
	profileImage: string;
	fullName: string;
	about: string;
	serviceType: string;
	location: string;
	createdAt: string;
};

export const VendorProfile = () => {
	// const [navigate] = useState([]);
	const [getVendorProfiles, setGetVendorProfiles] =
		useState<getUserType | null>();

	const { id = '' } = useParams();
	const navigate = useNavigate();

	const abortController = new AbortController();

	const loadVendorProfile = () => {
		getVendorProfile(abortController.signal, id)
			.then((response: any) => {
				console.log(response);
				setGetVendorProfiles(response.data);
			})
			.catch((e) => console.log(e));
	};

	useEffect(() => {
		loadVendorProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='container p-3'>
			<div className='card card-dash'>
				<h1 className='card-header'>Vendor Profile</h1>
			</div>

			<div className='text-end mb-3'>
				<button
					className='btn btn-light bg-color-brand text-white'
					onClick={() => navigate(-1)}
				>
					Back
				</button>
			</div>

			<div className='card'>
				{getVendorProfiles && (
					<div className='card-body'>
						<div className='comment mb-3'>
							<div className='row'>
								<div className='col-auto'>
									<a className='avatar avatar-xxl' href='profile-posts.html'>
										<img
											src={
												getVendorProfiles.profileImage
													? getVendorProfiles.profileImage
													: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
											}
											alt='...'
											className='avatar-img rounded-circle'
										/>
									</a>
								</div>
								<div className='col ms-n2'>
									<div className='comment-body'>
										<div className='row'>
											<div className='col'>
												<h2 className='comment-title'>
													{getVendorProfiles.fullName}
												</h2>
											</div>
										</div>

										<p className='comment-text'>{getVendorProfiles.location}</p>
									</div>
								</div>
							</div>
						</div>
						<div className='comment'>
							<div className='row'>
								<div className='col ms-n2'>
									<div className='comment-body'>
										<div className='row'>
											<div className='col'>
												<h5 className='comment-title'>About</h5>
											</div>
											{/* <div className="col-auto">
                      <time className="comment-time">11:12</time>
                    </div> */}
										</div>

										<p className='comment-text'>{getVendorProfiles.about}</p>
									</div>
								</div>
							</div>
						</div>
						<div className='comment'>
							<div className='row'>
								<div className='col ms-n2'>
									<div className='comment-body'>
										<div className='row'>
											<div className='col'>
												<h5 className='comment-title'>Date Joined</h5>
											</div>
											{/* <div className='col-auto'>
												<time className='comment-time'>11:12</time>
											</div> */}
										</div>

										<p className='comment-text'>
											{format(
												new Date(getVendorProfiles.createdAt),
												'eeee do LLLL, yyyy.'
											)}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className='comment'>
							<div className='row'>
								<div className='col ms-n2'>
									<div className='comment-body'>
										<div className='row'>
											<div className='col'>
												<h5 className='comment-title'>Services Offered</h5>
											</div>
											{/* <div className="col-auto">
                      <time className="comment-time">11:12</time>
                    </div> */}
										</div>

										<p className='comment-text'>
											{getVendorProfiles.serviceType}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
