import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { format } from 'date-fns';

import { getAllTransactions } from 'services/afric.service';

export const TransactionManagement = () => {
	const [transactions, setTransactions] = useState<any[]>([]);
	const [page, setPage] = useState(1);
	const [type, setType] = useState('');
	const [amountText, setAmountText] = useState('');
	const [amount] = useDebounce(amountText, 2000);

	const abortController = new AbortController();

	useEffect(() => {
		getAllTransactions(abortController.signal, type, amount).then(
			(res: any) => {
				setTransactions(res.data);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount, type]);

	const limit = 10;

	const lastIndex = page * limit;
	const firstIndex = lastIndex - limit;

	const currentTransactions =
		transactions && transactions.slice(firstIndex, lastIndex);

	const nPages = Math.ceil(transactions && transactions.length / limit);

	return (
		<div className='container p-3'>
			<div className='card card-dash'>
				<h2 className='card-header'>Transactions</h2>
			</div>
			<div className='card'>
				<div className='card-body'>
					<div className='row'>
						<div className='col-4 p-3'>
							<label>Transaction Type</label>
							<select
								className='form-select my-2'
								data-choices='{"searchEnabled": false}'
								onChange={(e) => setType(e.target.value)}
							>
								<option value=''>Select Transaction Type</option>
								<option value='payment'>Payment</option>
								<option value='withdrawal'>Withdrawal</option>
								<option value='subscription'>Subscription</option>
							</select>
						</div>
						<div className='col-4 p-3'>
							<label>Transaction Amount</label>
							<input
								className='form-control my-2'
								type='text'
								placeholder='Enter Amount'
								onChange={(e) => setAmountText(e.target.value)}
							/>
						</div>
					</div>
					<div className='table-responsive mb-0'>
						<table className='table table-sm table-nowrap card-table  '>
							<thead>
								<tr>
									<th>Description</th>
									<th>Date</th>
									<th>Amount</th>
									<th>Type</th>
									<th>From</th>
									<th>To</th>
								</tr>
							</thead>
							<tbody>
								{transactions &&
									currentTransactions &&
									currentTransactions.map((transaction) => (
										<tr>
											<td>{transaction.description}</td>
											<td>
												{format(new Date(transaction.createdAt), 'MM/dd/yyyy')}
											</td>
											<td>
												<span className='fw-bold'>NGN</span>{' '}
												{transaction.amount.toLocaleString()}
											</td>
											<td>
												<span
													className={`text-capitalize text-${
														transaction.type === 'payment'
															? 'success'
															: 'danger'
													}`}
												>
													{transaction.type}
												</span>
											</td>
											<td>
												<span className='text-primary'>{transaction.from}</span>
											</td>
											<td>
												<span className='text-primary'>{transaction.to}</span>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<div className='col-12 mt-3'>
						<div className='d-flex flex-row justify-content-between'>
							{page > 1 && (
								<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-0 pe-4 border-end  '
											href='#!'
											onClick={() => {
												if (page !== 1) setPage(page - 1);
											}}
										>
											<i className='fe fe-arrow-left me-1'></i> Prev
										</a>
									</li>
								</ul>
							)}

							<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

							{currentTransactions.length === limit && (
								<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-4 pe-0 border-start  '
											href='#!'
											onClick={() => {
												if (page !== nPages) setPage(page + 1);
											}}
										>
											Next <i className='fe fe-arrow-right ms-1'></i>
										</a>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
