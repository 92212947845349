import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { disableUser } from 'services/afric.service';

type toBlockType = { id: string; show: boolean; type: string };

export const BlockModal = ({
	toBlock,
	handleClose,
	load,
}: {
	toBlock: toBlockType;
	handleClose: () => void;
	load: Function;
}) => {
	const [loading, setLoading] = useState('idle');
	const abortController = new AbortController();

	const handleSubmit = async () => {
		setLoading('loading');
		console.log(toBlock.id);
		disableUser(toBlock.id, toBlock.type, abortController.signal).finally(
			() => {
				setLoading('idle');
				handleClose();
				load();
			}
		);
	};

	return (
		<Modal
			show={toBlock.show}
			onHide={handleClose}
			centered
			className='pt-4 md-modal-dialog align-items-end '
		>
			<form>
				<Modal.Body className='p-4'>
					<h3 className='text-center'>
						{toBlock.type === 'block' ? 'Block User?' : 'Unblock User?'}
					</h3>
					<h3>Are you sure?</h3>

					<div className='d-flex'>
						<div>
							<button className='p-3 rounded btn-white border-color-brand'>
								Cancel
							</button>
						</div>
						&nbsp; &nbsp;
						<div className='w-50'>
							<button
								type='submit'
								className='rounded p-3 btn btn-white bg-color-brand text-white'
								disabled={loading === 'loading'}
								onClick={handleSubmit}
							>
								{loading === 'loading' ? (
									<div
										className='spinner-border spinner-border-sm'
										role='status'
									></div>
								) : (
									<span>Continue</span>
								)}
							</button>
						</div>
					</div>
				</Modal.Body>
			</form>
		</Modal>
	);
};
