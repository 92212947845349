import { AuthRequest, AuthResponse } from 'interfaces/auth.interface';
import { ApiResponse, BaseApiResponse } from 'interfaces/base.interface';
import { CustomerResponse } from 'interfaces/users.interface';
import { fetchCookie } from 'utils/cookies.util';

export const authenticate = (
	payload: AuthRequest,
	signal: AbortSignal
): Promise<BaseApiResponse<AuthResponse>> => {
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/auth/login`, {
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			signal: signal,
			body: JSON.stringify(payload),
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<AuthResponse>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//-----------------------------------------------Vendor ------------------------------------------------------//

export const getAllVendors = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-users?role=vendor`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getAllVendorCategories = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-vendor-categories`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const addVendorCategory = (
	payload: any,
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/add-vendor-category`,
			{
				headers: {
					// "Content-Type": "multipart/form/data",
					Authorization: `Bearer ${_token}`,
				},
				method: 'POST',
				signal: signal,
				body: payload,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const removeVendorCategory = (
	signal: AbortSignal,
	id: string
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/delete-vendor-category/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'DELETE',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//------------------------------------------------------Inspiration -------------------------------------//

export const getAllInspirations = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-inspirations`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'POST',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const addInspiration = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/admin/add-inspiration`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'POST',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getImages = (
	signal: AbortSignal,
	page: number,
	limit: number
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/boards/posts?page=${page}&limit=${limit} `,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const deleteImage = (
	signal: AbortSignal,
	id: string
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/delete-images-for-user-inspiration-board/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'DELETE',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//--------------------------------------------------User -------------------------------------------//

export const getUsers = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-users?role=customer`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getCountOfCustomer = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-user-stats?role=customer&deactivation=`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getCountOfVendor = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-user-stats?role=vendor&deactivation=`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getUsersAndStats = (
	signal: AbortSignal,
	role: string,
	status: string
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-users?role=${role}&status=${status}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getVendorProfile = (
	signal: AbortSignal,
	id: string
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/user/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'GET',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.status === 'success') {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

// export const getCustomerProfile = (
//   signal: AbortSignal,
//   id: string
// ): Promise<BaseApiResponse<CustomerResponse[]>> => {
//   const _token = fetchCookie("1Q_SPA");
//   return new Promise((resolve, reject) => {
//     return fetch(`${process.env["REACT_APP_BASE_URL"]}/user/${id}`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${_token}`,
//       },
//       method: "GET",
//       signal: signal,
//     })
//       .then((res) => {
//         return res.json();
//       })
//       .then((authRes: BaseApiResponse<CustomerResponse[]>) => {
//         if (authRes.status === "success") {
//           resolve(authRes);
//         } else {
//           reject(authRes);
//         }
//       })
//       .catch((ex) => {
//         reject(ex);
//       });
//   });
// };

//--------------------------------------------------Manage Admin ---------------------------------------------//
export const addAdmin = (signal: AbortSignal, formData: any) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/admin/create-admin`, {
			headers: {
				// 'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${_token}`,
			},
			method: 'POST',
			signal: signal,
			body: formData,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getAllAdminUsers = (signal: AbortSignal) => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-users?role=admin`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (res.success) {
					resolve(res);
				} else {
					reject(res);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//-----------------------------------Reports ---------------------------------------------------------------//
export const getAllReports = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/admin/get-reports`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'GET',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getReportDetails = (
	signal: AbortSignal,
	id: string
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-report-details/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const resolveReport = (
	signal: AbortSignal,
	id: any
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/resolve-report/${id}`,
			{
				headers: {
					Authorization: `Bearer ${_token}`,
				},
				method: 'PATCH',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const unResolveReport = (
	signal: AbortSignal,
	id: any
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/unresolve-report/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'PATCH',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//----------------------------------------------Subscriptions---------------------------------------------------------//
export const getCountOfSubscribers = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/view-amount-of-subscribers`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getSubscribers = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/list-subscribers`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getSubscriptionPlans = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/subscriptions`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'GET',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const addSubscriptionPlan = (signal: AbortSignal, payload: any) => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/subscriptions`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'POST',
			signal: signal,
			body: JSON.stringify(payload),
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const deleteSubscriptionPlan = (signal: AbortSignal, id: string) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/subscriptions/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'DELETE',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const editSubscriptionPlan = (
	signal: AbortSignal,
	id: string,
	formData: any
) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/subscriptions/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'PUT',
			signal: signal,
			body: JSON.stringify(formData),
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//----------------------------------------------Wallets---------------------------------------------------------//
export const getWalletBalance = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/payment/wallet-balance`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getAllUnreleasedFunds = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/payment/unreleased-funds`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getAllEarnings = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/payment/get-earnings`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'GET',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//................................................................settings.........................................................//

export const disableUser = (
	userId: string,
	type: string,
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/disable-user/${userId}?filter=${type}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'PUT',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//---------------------------------------------- Transactions ---------------------------------------------------------//
export const getAllTransactions = (
	signal: AbortSignal,
	type: string,
	amount: string
) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-transaction${
				type !== '' ? `?type=${type}` : ''
			}${type !== '' && amount !== '' ? '&' : ''}${
				amount !== '' && type !== ''
					? `amount=${amount}`
					: amount !== '' && type === ''
					? `?amount=${amount}`
					: ''
			}`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//---------------------------------------------- Notifications ---------------------------------------------------------//
export const sendNotificationAll = (signal: AbortSignal, data: any) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/send-notifications`,
			{
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'POST',
				signal: signal,
				body: JSON.stringify(data),
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//---------------------------------------------- Vendor Management ---------------------------------------------------------//
export const getApprovedVendors = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-approved-vendors`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getUnapprovedVendors = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-unapproved-vendors`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const approveVendor = (
	signal: AbortSignal,
	id: any
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/approve-vendor-profile/${id}`,
			{
				headers: {
					Authorization: `Bearer ${_token}`,
				},
				method: 'PATCH',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const disApproveVendor = (
	signal: AbortSignal,
	id: any
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/disapprove-vendor-profile/${id}`,
			{
				headers: {
					Authorization: `Bearer ${_token}`,
				},
				method: 'PATCH',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//---------------------------------------------- Project Management ---------------------------------------------------------//
export const getApprovedProjects = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-approved-vendor-projects`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const getUnapprovedProjects = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-unapproved-vendor-projects`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const approveProject = (
	signal: AbortSignal,
	id: any
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/approve-vendor-project/${id}`,
			{
				headers: {
					Authorization: `Bearer ${_token}`,
				},
				method: 'PATCH',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const disApproveProject = (
	signal: AbortSignal,
	id: any
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/disapprove-vendor-project/${id}`,
			{
				headers: {
					Authorization: `Bearer ${_token}`,
				},
				method: 'PATCH',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes.success) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//---------------------------------------------- FAQ ---------------------------------------------------------//
export const getFAQs = (
	signal: AbortSignal
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/faq/get-faqs`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'GET',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const addFAQ = (signal: AbortSignal, payload: any) => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/faq/add-faq`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'POST',
			signal: signal,
			body: JSON.stringify(payload),
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const editFAQ = (signal: AbortSignal, id: string, formData: any) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/faq/update-faq/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'PATCH',
			signal: signal,
			body: JSON.stringify(formData),
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

export const deleteFAQ = (signal: AbortSignal, id: string) => {
	const _token = fetchCookie('1Q_SPA');

	return new Promise((resolve, reject) => {
		return fetch(`${process.env['REACT_APP_BASE_URL']}/faq/delete-faq/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${_token}`,
			},
			method: 'DELETE',
			signal: signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((authRes: ApiResponse) => {
				if (authRes) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};

//---------------------------------------------- Dashboard ---------------------------------------------------------//
export const countUsersWithDuration = (
	signal: AbortSignal,
	role: string,
	year: string
): Promise<BaseApiResponse<CustomerResponse[]>> => {
	const _token = fetchCookie('1Q_SPA');
	return new Promise((resolve, reject) => {
		return fetch(
			`${process.env['REACT_APP_BASE_URL']}/admin/get-number-of-registered-users?role=${role}&period=${year}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${_token}`,
				},
				method: 'GET',
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((authRes: BaseApiResponse<CustomerResponse[]>) => {
				if (authRes) {
					resolve(authRes);
				} else {
					reject(authRes);
				}
			})
			.catch((ex) => {
				reject(ex);
			});
	});
};
