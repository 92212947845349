import React, { useEffect, useState } from 'react';

import { getFAQs, addFAQ, deleteFAQ, editFAQ } from 'services/afric.service';

export const FAQs = () => {
	const [faqs, setFaqs] = useState<any[]>([]);
	const [formData, setFormData] = useState<any>({
		question: '',
		answer: '',
	});
	const [editId, setEditId] = useState('');

	const abortController = new AbortController();

	useEffect(() => {
		getFAQs(abortController.signal).then((res: any) => {
			setFaqs(res.data);
		});
	}, [abortController.signal]);

	const handleSubmit = (e: any) => {
		e.preventDefault();

		addFAQ(abortController.signal, formData).then((res: any) => {
			window.alert('FAQ Added Successfully');
			console.log(res);
			setFormData({});
		});
	};

	const handleDelete = (id: string) => {
		deleteFAQ(abortController.signal, id)
			.then((res: any) => {
				// window.alert(res.message);
				window.alert('FAQ Deleted Successfully');
				console.log(res);
			})
			.catch((err) => console.log(err));
	};

	const handleEdit = (id: string) => {
		editFAQ(abortController.signal, id, formData)
			.then((res: any) => {
				// window.alert(res.message);
				window.alert('FAQ Edited Successfully');
				console.log(res);
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className='container mt-4'>
			<div className='card card-dash'>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col-8'>
							<h2 className='card-header-title'>Frequently Asked Questions</h2>
						</div>
						<div className='col-4'>
							<div className='dropdown float-end'>
								<button
									className='btn btn-light'
									type='button'
									id='newUser'
									data-bs-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
								>
									New FAQ
								</button>
								<form
									className='dropdown-menu dropdown-menu-end dropdown-menu-card mt-1'
									aria-labelledby='newUser'
									onSubmit={handleSubmit}
								>
									<div className='card-header'>
										<h4 className='card-header-title'>Add new FAQ</h4>
									</div>

									<div className='card-header'>
										<div className='row g-0 align-items-center'>
											<div className='col-3'>
												<label className='mb-0' htmlFor='addUserName'>
													Question
												</label>
											</div>
											<div className='col'>
												<input
													className='form-control form-control-flush'
													id='addFAQanswer'
													type='text'
													placeholder='Question'
													value={formData.question}
													onChange={(e) =>
														setFormData({
															...formData,
															question: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className='card-header'>
										<div className='row g-0 align-items-center'>
											<div className='col-3'>
												<label className='mb-0' htmlFor='addUserEmail'>
													Answer
												</label>
											</div>
											<div className='col'>
												<input
													className='form-control form-control-flush'
													id='addFAQanswer'
													type='text'
													placeholder='Answer'
													value={formData.answer}
													onChange={(e) =>
														setFormData({ ...formData, answer: e.target.value })
													}
												/>
											</div>
										</div>
									</div>

									<div className='card-footer'>
										<button
											className='btn w-100 btn-white bg-color-brand text-white'
											type='submit'
										>
											Add FAQ
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-12'>
				<div className='row'>
					{faqs &&
						faqs.map((faq) => (
							<div className='col-6' key={faq._id}>
								<div className='card'>
									<div className='card-body'>
										<div className='list-group list-group-flush my-n3'>
											<div className='list-group-item pb-0'>
												<div className='row align-items-center'>
													<div className='col ms-n2'>
														<h3 className='mb-3'>{faq.question}</h3>

														<p className='p-0 text-secondary fw-bold  '>
															{faq.answer}
														</p>
													</div>
													<div className='col-auto'>
														<div className='dropdown'>
															<a
																href='/#'
																className='dropdown-ellipses dropdown-toggle'
																role='button'
																data-bs-toggle='dropdown'
																aria-haspopup='true'
																aria-expanded='false'
															>
																<i className='fe fe-more-vertical'></i>
															</a>
															<div className='dropdown-menu dropdown-menu-end'>
																<a
																	href='#!'
																	className='dropdown-item'
																	data-bs-toggle='modal'
																	data-bs-target='#modalEdit'
																	onClick={() => {
																		setEditId(faq._id);
																		setFormData({
																			question: faq.question,
																			answer: faq.answer,
																		});
																	}}
																>
																	Edit FAQ
																</a>
																<button
																	className='dropdown-item'
																	onClick={() => handleDelete(faq._id)}
																>
																	Delete FAQ
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>

			<div
				className='modal fade'
				id='modalEdit'
				tabIndex={-1}
				role='dialog'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content'>
						<div className='modal-card card'>
							<div className='card-header'>
								<h2 className='card-header-title' id='exampleModalCenterTitle'>
									Edit FAQ - {formData.question}
								</h2>

								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
									onClick={(e) => {
										e.preventDefault();
										setFormData({});
									}}
								></button>
							</div>

							<div className='card-body'>
								<div className='form-group'>
									<label className='form-label'>Question</label>
									<input
										type='text'
										className='form-control'
										defaultValue={formData.question}
										onChange={(e) =>
											setFormData({
												...formData,
												question: e.target.value,
											})
										}
									/>
								</div>

								<div className='form-group'>
									<label className='form-label'>Answer</label>
									<input
										type='text'
										className='form-control'
										defaultValue={formData.answer}
										onChange={(e) =>
											setFormData({
												...formData,
												answer: e.target.value,
											})
										}
									/>
								</div>

								<button
									className='btn btn-white bg-color-brand text-white'
									onClick={() => handleEdit(editId)}
								>
									Update
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
