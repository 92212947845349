import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { removeCookie } from 'utils/cookies.util';

import logo from '../../assets/afric-logo.png';

export const SideBar = () => {
	const pathname = useLocation().pathname;

	const logout = () => {
		removeCookie('1Q_SPA');
		window.location.reload();
	};

	return (
		<>
			<nav
				className='navbar navbar-vertical fixed-start navbar-expand-md p-4 '
				id='sidebar'
			>
				<div className='container-fluid'>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#sidebarCollapse'
						aria-controls='sidebarCollapse'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>

					<div className='mb-3'>
						<Link to='/' style={{ textDecoration: 'none' }}>
							{/* <h3 className='mb-1'>
								<span className='fw-bolder'>Afric</span>
								<span className='fw-normal'>Weddings</span>
							</h3> */}
							<img
								src={logo}
								alt=''
								style={{
									width: '100%',
								}}
							/>
						</Link>
						<p className='fs-12 text-white'>Internal Dashboard</p>
					</div>

					<div className='navbar-user d-md-none'>
						<div className='dropdown'>
							<a
								href='#!'
								id='sidebarIcon'
								className='dropdown-toggle'
								role='button'
								data-bs-toggle='dropdown'
								aria-haspopup='true'
								aria-expanded='false'
							>
								<div className='avatar avatar-sm avatar-online'>
									<img
										src='assets/img/avatars/profiles/avatar-1.jpg'
										className='avatar-img rounded-circle'
										alt='...'
									/>
								</div>
							</a>

							<div
								className='dropdown-menu dropdown-menu-end'
								aria-labelledby='sidebarIcon'
							>
								<a href='profile-posts.html' className='dropdown-item'>
									Profile
								</a>
								<a href='account-general.html' className='dropdown-item'>
									Settings
								</a>
								<hr className='dropdown-divider' />
								<a href='sign-in.html' className='dropdown-item'>
									Logout
								</a>
							</div>
						</div>
					</div>

					<div className='collapse navbar-collapse' id='sidebarCollapse'>
						<form className='mt-4 mb-3 d-md-none'>
							<div className='input-group input-group-rounded input-group-merge input-group-reverse'>
								<input
									className='form-control'
									type='search'
									placeholder='Search'
									aria-label='Search'
								/>
								<div className='input-group-text'>
									<span className='fe fe-search'></span>
								</div>
							</div>
						</form>

						<ul className='navbar-nav'>
							<li className='nav-item'>
								<div className='nav-link align-items-baseline'>
									<i className='fe fe-users'></i>
									<h5 className='m-0'>ACCOUNTS</h5>
								</div>

								<div>
									<ul className='nav nav-sm flex-column'>
										<Link to='/customer'>
											<li className='nav-item'>
												<a
													href='#!'
													className={`${
														pathname === '/customer'
															? 'nav-link active'
															: 'nav-link'
													}`}
												>
													Customers
												</a>
											</li>
										</Link>

										<Link to='/vendor'>
											<li className='nav-item'>
												<a
													href='#!'
													className={`${
														pathname === '/vendor'
															? 'nav-link active'
															: 'nav-link'
													}`}
												>
													Vendors
												</a>
											</li>
										</Link>

										<Link to='/subscriptions'>
											<li className='nav-item'>
												<a
													href='#!'
													className={`${
														pathname === '/subscriptions'
															? 'nav-link active'
															: 'nav-link'
													}`}
												>
													Subscriptions
												</a>
											</li>
										</Link>
									</ul>
								</div>
							</li>

							<li className='nav-item mt-3'>
								<div className='nav-link align-items-center'>
									<i className='fe fe-grid'></i>
									<h5> PLATFORM MANAGEMENT</h5>
								</div>
							</li>

							<Link to='/notifications'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/notifications'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Notifications
									</a>
								</li>
							</Link>

							<Link to='/vendor-categories'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/vendor-categories'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Vendor Categories
									</a>
								</li>
							</Link>

							<Link to='/chat-management'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/chat-management'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Chat Management
									</a>
								</li>
							</Link>

							<Link to='/transactions'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/transactions'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Transaction Management
									</a>
								</li>
							</Link>

							<Link to='/reports'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/reports' ? 'nav-link active' : 'nav-link'
										}`}
									>
										Reports
									</a>
								</li>
							</Link>

							<Link to='/manage-content'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/manage-content'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Content Management
									</a>
								</li>
							</Link>

							<Link to='/manage-vendors'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/manage-vendors'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Vendor Management
									</a>
								</li>
							</Link>
						
							<Link to='/manage-projects'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/manage-projects'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Project Management
									</a>
								</li>
							</Link>

							<Link to='/subscription-plans'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/subscription-plans'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Subscription Plans
									</a>
								</li>
							</Link>

							<Link to='/faqs'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/faqs'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										FAQs
									</a>
								</li>
							</Link>

							<li className='nav-item mt-3'>
								<div className='nav-link align-items-baseline'>
									<i className='fe fe-settings'></i>
									<h5>SETTINGS</h5>
								</div>
							</li>

							<Link to='/role-management'>
								<li className='nav-item'>
									<a
										href='#!'
										className={`${
											pathname === '/role-management'
												? 'nav-link active'
												: 'nav-link'
										}`}
									>
										Role Management
									</a>
								</li>
							</Link>

							{/* <Link to='/account'> */}
							<li className='nav-item'>
								<a
									href='#!'
									className={`${
										pathname === '/customer' ? 'nav-link active' : 'nav-link'
									}`}
								>
									Account Settings
								</a>
							</li>
							{/* </Link> */}

							<div>
								<ul
									className='nav nav-pills flex-column'
									style={{ marginTop: '200px' }}
								>
									<li className='nav-item px-5 py-3'>
										<button
											className='nav-link border-top border-bottom w-100 bg-light logout-button gradient-color'
											aria-current='page'
											onClick={logout}
										>
											<i
												className='fa-solid fa-arrow-right-from-bracket align-middle me-3'
												style={{
													color: 'red !important',
												}}
											></i>
											<span className='align-middle fs-14 text-color-brand'>
												LOGOUT
											</span>
										</button>
									</li>
								</ul>
							</div>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};
