import { useSessionStorage } from '../hooks';
import { fetchCookie } from '../utils/cookies.util';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

export const SessionRoutes = () => {
	const location = useLocation();
	const [user] = useSessionStorage('user', {});
	const auth = {
		isAuthenticated: fetchCookie('1Q_SPA') ? true : false,
	};

	return auth.isAuthenticated ? (
		<Navigate
			to={user && user?.role === 'admin' && '/dashboard/'}
			state={{ from: location }}
			replace
		/>
	) : (
		<Outlet />
	);
};
