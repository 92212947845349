/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { getAllAdminUsers, addAdmin } from 'services/afric.service';

export const RoleManagement = () => {
	const [admins, setAdmins] = useState<any[]>([]);
	const [image, setImage] = useState<any>(null);

	const [formData, setFormData] = useState<any>({
		fullName: '',
		email: '',
		password: '',
		role: 'admin',
		phone: '',
		permissions: [],
	});

	const abortController = new AbortController();

	useEffect(() => {
		getAllAdminUsers(abortController.signal).then((res: any) => {
			setAdmins(res.data.results);
		});
	}, [abortController.signal, admins]);

	const handleSubmit = (e: any) => {
		e.preventDefault();

		const form = new FormData();

		for (let key in formData) {
			form.append(key, formData[key]);
		}

		form.append('image', image);

		addAdmin(abortController.signal, form).then((res: any) => {
			window.alert(res.message);
			getAllAdminUsers(abortController.signal);
		});
	};

	const handlePermissionsCheck = (e: any) => {
		let permissionList = formData.permissions;
		let check = e.target.checked;

		if (check) {
			setFormData({
				...formData,
				permissions: [...formData.permissions, e.target.value],
			});
		} else {
			let index = permissionList.indexOf(e.target.value);

			if (index > -1) {
				permissionList.splice(index, 1);
				setFormData({
					...formData,
					permissions: permissionList,
				});
			}
		}
	};

	return (
		<div className='card m-3 py-3'>
			<div className='card-header card-dash'>
				<h2 className='card-header-title'>Admins</h2>

				<div className='dropdown'>
					<button
						className='btn btn-white'
						type='button'
						id='newUser'
						data-bs-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						New Admin
					</button>
					<form
						className='dropdown-menu dropdown-menu-end dropdown-menu-card mt-1'
						aria-labelledby='newUser'
						onSubmit={handleSubmit}
					>
						<div className='card-header'>
							<h4 className='card-header-title'>Add new Admin User</h4>
						</div>
						<div className='card-header'>
							<input
								className='form-control'
								type='file'
								onChange={(e) => setImage(e.target!.files![0])}
							/>
						</div>
						<div className='card-header'>
							<div className='row g-0 align-items-center'>
								<div className='col-3'>
									<label className='mb-0' htmlFor='addUserName'>
										Name
									</label>
								</div>
								<div className='col'>
									<input
										className='form-control form-control-flush'
										id='addUserName'
										type='text'
										placeholder='Full name'
										value={formData.fullName}
										onChange={(e) =>
											setFormData({ ...formData, fullName: e.target.value })
										}
									/>
								</div>
							</div>
						</div>
						<div className='card-header'>
							<div className='row g-0 align-items-center'>
								<div className='col-3'>
									<label className='mb-0' htmlFor='addUserEmail'>
										Email
									</label>
								</div>
								<div className='col'>
									<input
										className='form-control form-control-flush'
										id='addUserEmail'
										type='text'
										placeholder='Email address'
										value={formData.email}
										onChange={(e) =>
											setFormData({ ...formData, email: e.target.value })
										}
									/>
								</div>
							</div>
						</div>
						<div className='card-header'>
							<div className='row g-0 align-items-center'>
								<div className='col-3'>
									<label className='mb-0' htmlFor='addUserEmail'>
										Phone
									</label>
								</div>
								<div className='col'>
									<input
										className='form-control form-control-flush'
										id='addUserPhone'
										type='text'
										placeholder='Phone number'
										value={formData.phone}
										onChange={(e) =>
											setFormData({ ...formData, phone: e.target.value })
										}
									/>
								</div>
							</div>
						</div>
						<div className='card-header'>
							<div className='row g-0 align-items-center'>
								<div className='col-3'>
									<label className='mb-0' htmlFor='addUserEmail'>
										Password
									</label>
								</div>
								<div className='col'>
									<input
										className='form-control form-control-flush'
										id='addUserPassword'
										type='text'
										placeholder='Password'
										value={formData.password}
										onChange={(e) =>
											setFormData({ ...formData, password: e.target.value })
										}
									/>
								</div>
							</div>
						</div>
						<div className='card-header'>
							<div className='row g-0 align-items-center'>
								<div className='col-12'>
									<label className='mb-0' htmlFor='addUserEmail'>
										Permissions
									</label>
								</div>
								<div className='col'>
									<input
										className='form-check-input'
										type='checkbox'
										value='customers'
										onChange={handlePermissionsCheck}
									/>
									<label className='form-check-label mx-2'>Customers</label>

									<input
										className='form-check-input'
										type='checkbox'
										value='vendors'
										onChange={handlePermissionsCheck}
									/>
									<label className='form-check-label mx-2'>Vendors</label>
								</div>
							</div>
						</div>
						<div className='card-footer'>
							<button className='btn w-100 bg-color-brand text-white' type='submit'>
								Add Admin
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className='card-body'>
				<div className='list-group list-group-flush my-n3'>
					{admins.map((admin: any) => {
						return (
							<div className='list-group-item'>
								<div className='row align-items-center jus'>
									<div className='col-auto'>
										<a href='profile-posts.html' className='avatar'>
											<img
												src={admin.user.profileImage || null}
												alt='...'
												className='avatar-img rounded-circle'
											/>
										</a>
									</div>
									<div className='col-5 ms-n2'>
										<h4 className='mb-1  '>
											<a href='#!'>{admin.user.fullName}</a>
										</h4>

										<p className='small text-muted mb-0'>
											<a
												className='d-block text-reset text-truncate'
												href='mailto:dianna.smiley@company.com'
											>
												{admin.user.email}
											</a>
										</p>
									</div>
									<div className='col-auto ms-auto me-n3'>
										{admin.permission &&
											admin.permission.permissions.map((item: string) => (
												<p className='m-1 badge bg-primary py-2 px-3 text-capitalize'>
													{item}
												</p>
											))}
									</div>
									<div className='col-auto'>
										<div className='dropdown'>
											<a
												className='dropdown-ellipses dropdown-toggle'
												href='#'
												role='button'
												data-bs-toggle='dropdown'
												aria-haspopup='true'
												aria-expanded='false'
											>
												<i className='fe fe-more-vertical  '></i>
											</a>
											<div className='dropdown-menu dropdown-menu-end'>
												<a className='dropdown-item' href='#!'>
													Delete
												</a>
												<a className='dropdown-item' href='#!'>
													Disable
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
