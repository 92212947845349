import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { getSubscribers } from 'services/afric.service';

export const Subscriptions = () => {
	const [subscribers, setSubscribers] = useState<any[]>([]);
	const [page, setPage] = useState(1);

	const abortController = new AbortController();

	useEffect(() => {
		getSubscribers(abortController.signal).then((res: any) => {
			setSubscribers(res.data.results);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const limit = 10;

	const lastIndex = page * limit;
	const firstIndex = lastIndex - limit;

	const currentSubscribers =
		subscribers && subscribers.slice(firstIndex, lastIndex);

	const nPages = Math.ceil(subscribers && subscribers.length / limit);

	return (
		<div className='container p-3'>
			<div className='card card-dash'>
				<h2 className='card-header'>Subscriptions</h2>
			</div>
			<div className='card'>
				<div className='card-body'>
					<div className='table-responsive mb-0'>
						<table className='table table-sm table-nowrap card-table'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Plan</th>
									<th>Amount</th>
									<th>Start</th>
									<th>End</th>
								</tr>
							</thead>
							<tbody>
								{subscribers &&
									currentSubscribers &&
									currentSubscribers.map((subscriber) => (
										<tr>
											<td>{subscriber.name}</td>
											<td>{subscriber.planName}</td>
											<td>
												<span className='fw-bold'>NGN</span>{' '}
												{subscriber.amount.toLocaleString()}
											</td>
											<td>
												{format(new Date(subscriber.startDate), 'MM/dd/yyyy')}
											</td>
											<td>
												{format(new Date(subscriber.endDate), 'MM/dd/yyyy')}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<div className='col-12 mt-3'>
						<div className='d-flex flex-row justify-content-between'>
							{page > 1 && (
								<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-0 pe-4 border-end'
											href='#!'
											onClick={() => {
												if (page !== 1) setPage(page - 1);
											}}
										>
											<i className='fe fe-arrow-left me-1'></i> Prev
										</a>
									</li>
								</ul>
							)}

							<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

							{currentSubscribers.length === limit && (
								<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-4 pe-0 border-start'
											href='#!'
											onClick={() => {
												if (page !== nPages) setPage(page + 1);
											}}
										>
											Next <i className='fe fe-arrow-right ms-1'></i>
										</a>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
