import { SignIn } from 'pages';
import { Customer } from 'pages/Customer';
import { CustomerProfile } from 'pages/CustomerProfile';
import { LandingPage } from 'pages/LandingPage';
import { RoleManagement } from 'pages/RoleManagement';
import { TransactionManagement } from 'pages/Transactions';
import { Subscriptions } from 'pages/Subscriptions';
import { ChatManagement } from 'pages/Chats/ChatManagement';
import { SingleChat } from 'pages/Chats/SingleChat';
import { SubscriptionPlans } from 'pages/SubscriptionPlans';
import { Notifications } from 'pages/Notifications';
import { ManageContent } from 'pages/ManageContent';
import { VendorCategories } from 'pages/vendorCategories';
import { VendorProfile } from 'pages/Vendors/VendorProfile';
import { Vendors } from 'pages/Vendors/vendors';
import { Reports } from 'pages/Reports/Reports';
import { VendorManagement } from 'pages/Vendors/VendorManagement';
import { ProjectManagement } from 'pages/Vendors/ProjectManagement';
import { FAQs } from 'pages/FAQs';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { SessionRoutes } from './SessionRoutes';

const AppRouter = () => {
	return (
		<Routes>
			<Route element={<SessionRoutes />}>
				<Route path='/' element={<SignIn />} />
				<Route path='/sign-in' element={<SignIn />} />
			</Route>

			<Route element={<PrivateRoutes />}>
				<Route path='/dashboard/' element={<LandingPage />} />
				<Route path='/customer' element={<Customer />} />
				<Route path='/customer/:id' element={<CustomerProfile />} />
				<Route path='/vendor' element={<Vendors />} />
				<Route path='/vendor-profile/:id' element={<VendorProfile />} />
				<Route path='/vendor-categories' element={<VendorCategories />} />
				<Route path='/transactions' element={<TransactionManagement />} />
				<Route path='/chat-management' element={<ChatManagement />} />
				<Route path='/chat-management/:id' element={<SingleChat />} />
				<Route path='/role-management' element={<RoleManagement />} />
				<Route path='/manage-content' element={<ManageContent />} />
				<Route path='/subscriptions' element={<Subscriptions />} />
				<Route path='/subscription-plans' element={<SubscriptionPlans />} />
				<Route path='/notifications' element={<Notifications />} />
				<Route path='/reports' element={<Reports />} />
				<Route path='/manage-vendors' element={<VendorManagement />} />
				<Route path='/manage-projects' element={<ProjectManagement />} />
				<Route path='/faqs' element={<FAQs />} />
			</Route>
		</Routes>
	);
};

export default AppRouter;
