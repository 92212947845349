import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
	getAllReports,
	getReportDetails,
	resolveReport,
	unResolveReport,
} from 'services/afric.service';

export const Reports = () => {
	const [reports, setReports] = useState<any[]>([]);
	const [reportId, setReportId] = useState('');
	const [reportDetails, setReportDetails] = useState<any>({});
	const [page, setPage] = useState(1);

	const abortController = new AbortController();

	useEffect(() => {
		getAllReports(abortController.signal).then((res: any) => {
			setReports(res.data.results);
		});

		if (reportId.length > 0) {
			getReportDetails(abortController.signal, reportId).then((res: any) => {
				setReportDetails(res.data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportId]);

	const limit = 10;

	const lastIndex = page * limit;
	const firstIndex = lastIndex - limit;

	const currentReports = reports && reports.slice(firstIndex, lastIndex);

	const nPages = Math.ceil(reports && reports.length / limit);

	const handleResolve = (id: string) => {
		resolveReport(abortController.signal, id).then((res: any) => {
			alert(res.message);
		});
	};

	const handleUnresolve = (id: string) => {
		unResolveReport(abortController.signal, id).then((res: any) => {
			alert(res.message);
		});
	};

	return (
		<div className='container p-3'>
			<div className='card card-dash'>
				<h2 className='card-header'>Reports</h2>
			</div>
			<div className='card'>
				<div className='card-body'>
					<div className='table-responsive mb-0'>
						<table className='table table-sm table-nowrap card-table  '>
							<thead>
								<tr>
									<th>Name</th>
									<th>Description</th>
									<th>Status</th>
									<th>Date</th>
									<th>ID</th>
								</tr>
							</thead>
							<tbody>
								{reports &&
									currentReports &&
									currentReports.map((report) => (
										<tr>
											<td>{report.name}</td>
											<td>{report.description}</td>
											<td
												className={`${
													report.status === 'open'
														? 'text-muted'
														: 'text-success'
												}`}
											>
												{report.status}
											</td>
											<td>
												{format(new Date(report.createdAt), 'MM/dd/yyyy')}
											</td>
											<td>
												<a
													href='#!'
													data-bs-toggle='modal'
													data-bs-target='#modalDetails'
													onClick={() => setReportId(report._id)}
												>
													{report._id}
												</a>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<div className='col-12 mt-3'>
						<div className='d-flex flex-row justify-content-between'>
							{page > 1 && (
								<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-0 pe-4 border-end  '
											href='#!'
											onClick={() => {
												if (page !== 1) setPage(page - 1);
											}}
										>
											<i className='fe fe-arrow-left me-1'></i> Prev
										</a>
									</li>
								</ul>
							)}

							<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

							{currentReports.length === limit && (
								<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-4 pe-0 border-start  '
											href='#!'
											onClick={() => {
												if (page !== nPages) setPage(page + 1);
											}}
										>
											Next <i className='fe fe-arrow-right ms-1'></i>
										</a>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>

			<div
				className='modal fade'
				id='modalDetails'
				tabIndex={-1}
				role='dialog'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content'>
						{reportDetails && Object.keys(reportDetails).length === 0 && (
							<p className='p-4'>Loading...</p>
						)}
						{reportDetails && Object.keys(reportDetails).length !== 0 && (
							<div className='modal-card card'>
								<div className='card-header'>
									<h2
										className='card-header-title'
										id='exampleModalCenterTitle'
									>
										{reportDetails?.name}
									</h2>

									<button
										type='button'
										className='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
										onClick={() => {
											setReportId('');
											setReportDetails({});
										}}
									></button>
								</div>

								<div className='card-body'>
									<div className='row'>
										<div className='col-12 px-4 py-3 bg-white border rounded mb-3'>
											<label className='text-black fw-bold'>Description</label>
											<p className='text-black'>{reportDetails.description}</p>
										</div>
										<div className='col-5 px-4 py-3 bg-white border rounded mb-3'>
											<label className='text-black fw-bold'>Status</label>
											<p
												className={`${
													reportDetails.status === 'open'
														? 'text-muted'
														: 'text-success'
												}`}
											>
												{reportDetails.status}
											</p>
										</div>
										<div className='col-1'></div>
										<div className='col-6 px-4 py-3 bg-white border rounded mb-3'>
											<label className='text-black fw-bold'>User ID</label>
											<p className='text-black'>{reportDetails.userID}</p>
										</div>
									</div>
									{reportDetails.status === 'open' && (
										<button
											className='btn btn-success'
											onClick={() => handleResolve(reportDetails._id)}
										>
											Resolve
										</button>
									)}
									{reportDetails.status !== 'open' && (
										<button
											className='btn btn-danger mx-2'
											onClick={() => handleUnresolve(reportDetails._id)}
										>
											Unresolve
										</button>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
