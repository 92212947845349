/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { LineChart, Line, Tooltip, XAxis, YAxis } from 'recharts';
import {
	getCountOfCustomer,
	getCountOfVendor,
	getCountOfSubscribers,
	getUsersAndStats,
	countUsersWithDuration,
} from 'services/afric.service';

export const LandingPage = () => {
	const [customerCount, setCustomerCount] = useState<number | null>();
	const [activeCustomerCount, setActiveCustomerCount] = useState<
		number | null
	>();
	const [inactiveCustomerCount, setInactiveCustomerCount] = useState<
		number | null
	>();
	const [vendorCount, setVendorCount] = useState<number | null>();
	const [activeVendorCount, setActiveVendorCount] = useState<number | null>();
	const [inactiveVendorCount, setInactiveVendorCount] = useState<
		number | null
	>();
	const [subscribersCount, setSubscribersCount] = useState<any>({});
	const [customerData, setCustomerData] = useState<any>([]);
	const [vendorData, setVendorData] = useState<any>([]);

	const [durationType, setDurationType] = useState('year');
	const [dataType, setDataType] = useState('customer');

	const abortController = new AbortController();

	const loadCount = () => {
		getCountOfCustomer(abortController.signal).then((response: any) => {
			setCustomerCount(response.data);
		});
	};

	const loadCountVendor = () => {
		getCountOfVendor(abortController.signal).then((response: any) => {
			setVendorCount(response.data);
		});
	};

	const loadCountSubscribers = () => {
		getCountOfSubscribers(abortController.signal).then((response: any) => {
			setSubscribersCount(response.data);
		});
	};

	const loadCountActiveCustomers = () => {
		getUsersAndStats(abortController.signal, 'customer', 'active').then(
			(response: any) => {
				setActiveCustomerCount(response.data.totalCount);
			}
		);
	};

	const loadCountInactiveCustomers = () => {
		getUsersAndStats(abortController.signal, 'customer', 'deactivated').then(
			(response: any) => {
				setInactiveCustomerCount(response.data.totalCount);
			}
		);
	};

	const loadCountActiveVendors = () => {
		getUsersAndStats(abortController.signal, 'vendor', 'active').then(
			(response: any) => {
				setActiveVendorCount(response.data.totalCount);
			}
		);
	};

	const loadCountInactiveVendors = () => {
		getUsersAndStats(abortController.signal, 'vendor', 'deactivated').then(
			(response: any) => {
				setInactiveVendorCount(response.data.totalCount);
			}
		);
	};

	const loadCountVendorDuration = (role = 'vendor', period = durationType) => {
		countUsersWithDuration(abortController.signal, role, period).then(
			(response: any) => {
				setVendorData(response.data);
			}
		);
	};

	const loadCountCustomerDuration = (
		role = 'customer',
		period = durationType
	) => {
		countUsersWithDuration(abortController.signal, role, period).then(
			(response: any) => {
				setCustomerData(response.data);
			}
		);
	};

	useEffect(() => {
		loadCount();
		loadCountVendor();
		loadCountSubscribers();
		loadCountActiveCustomers();
		loadCountInactiveCustomers();
		loadCountActiveVendors();
		loadCountInactiveVendors();
		loadCountVendorDuration();
		loadCountCustomerDuration();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataType, durationType]);

	const dataCustomerYear =
		customerData &&
		customerData.map((customer: any) => ({
			name: customer.year,
			Customers: customer.count,
		}));

	const dataCustomerMonth =
		customerData &&
		customerData.map((customer: any) => ({
			name: customer.month,
			Customers: customer.count,
		}));

	const dataVendorYear =
		vendorData &&
		vendorData.map((vendor: any) => ({
			name: vendor.year,
			Vendors: vendor.count,
		}));

	const dataVendorMonth =
		vendorData &&
		vendorData.map((vendor: any) => ({
			name: vendor.month,
			Vendors: vendor.count,
		}));

	const selectData = (dataType: string, duration: string) => {
		if (dataType === 'customer' && duration === 'year') {
			return dataCustomerYear;
		} else if (dataType === 'customer' && duration === 'month') {
			return dataCustomerMonth;
		} else if (dataType === 'vendor' && duration === 'year') {
			return dataVendorYear;
		} else if (dataType === 'vendor' && duration === 'month') {
			return dataVendorMonth;
		} else {
			return;
		}
	};

	return (
		<div>
			<div className='main-content'>
				<div className='header'>
					<div className='container-fluid'>
						<div className='header-body'>
							<div className='row align-items-end'>
								<div className='col card card-dash'>
									<div className='card-body'>
										<h6 className='header-pretitle text-white'>Overview</h6>
										<h1 className='header-title'>Dashboard</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-6 col-md-6'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase   mb-2'>Customers</h6>
											<span className='h2 mb-0'>{customerCount ?? 0}</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-user   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-6'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase   mb-2'>Vendors</h6>
											<span className='h2 mb-0'>{vendorCount ?? 0}</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-3'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-success mb-2'>
												Active Customers
											</h6>
											<span className='h2 mb-0'>
												{activeCustomerCount ?? 0}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-3'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-danger mb-2'>
												Inactive Customers
											</h6>
											<span className='h2 mb-0'>
												{inactiveCustomerCount ?? 0}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-3'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-success mb-2'>
												Active Vendors
											</h6>
											<span className='h2 mb-0'>{activeVendorCount ?? 0}</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-3'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase text-danger mb-2'>
												Inactive Vendors
											</h6>
											<span className='h2 mb-0'>
												{inactiveVendorCount ?? 0}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-4'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase   mb-2'>Subscribers</h6>
											<span className='h2 mb-0'>
												{subscribersCount.allCounts ?? 0}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-4'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase   mb-2'>
												Subscribers (Week)
											</h6>
											<span className='h2 mb-0'>
												{subscribersCount.subscribersWeek ?? 0}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-4'>
							<div className='card'>
								<div className='card-body'>
									<div className='row align-items-center gx-0'>
										<div className='col'>
											<h6 className='text-uppercase   mb-2'>
												Subscribers (Today)
											</h6>
											<span className='h2 mb-0'>
												{subscribersCount.subscribersToday ?? 0}
											</span>
										</div>
										<div className='col-auto'>
											<span className='h2 fe fe-briefcase   mb-0'></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='container-fluid'>
					<div className='card p-5'>
						<div className='row'>
							<div className='col-4 form-group'>
								<label className='mb-2'>Duration</label>
								<select
									className='form-control'
									defaultValue={dataType}
									onChange={(e) => setDurationType(e.target.value)}
								>
									<option value='year'>Year</option>
									<option value='month'>Month</option>
								</select>
							</div>
							<div className='col-4 form-group'>
								<label className='mb-2'>Type</label>
								<select
									className='form-control'
									defaultValue={dataType}
									onChange={(e) => setDataType(e.target.value)}
								>
									<option value='customer'>Customer</option>
									<option value='vendor'>Vendor</option>
								</select>
							</div>
						</div>
						{dataCustomerYear &&
							Object.keys(dataCustomerYear).length !== 0 &&
							vendorData &&
							Object.keys(vendorData).length !== 0 && (
								<div className='card-body'>
									<LineChart
										width={900}
										height={450}
										data={selectData(dataType, durationType)}
									>
										<Line
											type='monotone'
											dataKey='Customers'
											stroke='#8884d8'
										/>
										<Line type='monotone' dataKey='Vendors' stroke='#8884d8' />
										<XAxis dataKey='name' />
										<YAxis />
										<Tooltip />
									</LineChart>
								</div>
							)}
					</div>
				</div>
			</div>
		</div>
	);
};
