import React from 'react';
import { SideBar } from './SideBar';
type Props = {
	children?: JSX.Element | JSX.Element[];
};

export const DashboardLayout: React.FC<Props> = ({ children }: Props) => {
	return (
		<>
			<SideBar />
			<div className='main-content'>{children}</div>
		</>
	);
};
