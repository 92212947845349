import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { removeVendorCategory } from "services/afric.service";

type toDeleteType = { id: string; show: boolean };

export const DeleteModal = ({
  toDelete,
  handleClose,
  load,
}: {
  toDelete: toDeleteType;
  handleClose: () => void;
  load: Function;
}) => {
  const [loading, setLoading] = useState("idle");
  const abortController = new AbortController();

  const handleSubmit = async () => {
    setLoading("loading");
    removeVendorCategory(abortController.signal, toDelete.id).finally(() => {
      setLoading("idle");
      handleClose();
      load();
    });
  };

  return (
    <Modal
      show={toDelete.show}
      onHide={handleClose}
      centered
      className="pt-4 md-modal-dialog align-items-end "
    >
      <form>
        <Modal.Body className="p-4">
          <h3 className="text-center">Delete?</h3>
          <h3>You're about to delete</h3>

          <div className="d-flex">
            <div className="">
              <button
                style={{
                  // color: "#e00253",
                  // borderColor: "#e00253",
                  color: "#000080",
                  borderColor: "#000080",
                  backgroundColor: "#ffff",
                  // padding: "15px",
                }}
                className="p-3 rounded"
                // onClick={handleClose}
              >
                Cancel
              </button>
            </div>
            &nbsp; &nbsp;
            <div className="w-50">
              <button
                style={{
                  color: "#ffff",
                  borderColor: "#000080",
                  backgroundColor: "#000080",
                  // borderColor: "#e00253",
                  // backgroundColor: "#e00253",
                }}
                type="submit"
                className="rounded p-3"
                disabled={loading === "loading"}
                onClick={handleSubmit}
              >
                {loading === "loading" ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                ) : (
                  <span>Delete</span>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};
