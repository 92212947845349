import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { getFirestore, doc, getDoc } from 'firebase/firestore';

import { app } from '../../utils/firebase.util';

export const SingleChat = () => {
	const [chat, setChat] = useState<any>({});
	const [from, setFrom] = useState('');
	const [fromID, setFromID] = useState('');
	const [to, setTo] = useState('');
	const [toID, setToID] = useState('');

	const navigate = useNavigate();

	let { id } = useParams();

	const db = getFirestore(app);

	useEffect(() => {
		const getChat = async (chatID: string) => {
			const ref = doc(db, 'messages', chatID);
			const snap = await getDoc(ref);

			if (snap.exists()) {
				const data = {
					chats: snap.data()['chats'],
					members: snap.data()['members'],
				};

				setChat(data);
				setFromID(data.members[0]);
				setToID(data.members[1]);
			} else {
				return 'Unknown';
			}
		};

		const getUserName = async (id: string) => {
			const ref = doc(db, 'users', id);
			const snap = await getDoc(ref);

			if (snap.exists()) {
				return snap.data()['fullName'];
			} else {
				return 'Unknown';
			}
		};

		getChat(id || '');
		getUserName(fromID).then((name) => setFrom(name));
		getUserName(toID).then((name) => setTo(name));
	}, [db, fromID, id, toID]);

	if (!from || !to || !fromID || !toID) {
		return <h3>Loading...</h3>;
	}

	return (
		<div className='container p-3'>
			<div className='my-4 text-end'>
				<button className='btn btn-dark' onClick={() => navigate(-1)}>
					Back
				</button>
			</div>

			<div className='card'>
				<h2 className='card-header'>
					Chat between {from} and {to}{' '}
				</h2>

				<div className='card-body'>
					{chat &&
						Object.keys(chat).length !== 0 &&
						chat.chats.map((message: any) => (
							<div className='row mb-1'>
								<div className='col-9'>
									<p className='m-0'>
										{message.from_uid === fromID ? (
											<span className='text-primary'>{from}</span>
										) : (
											<span className='text-success'>{to}</span>
										)}
										:
										<span className='mx-3'>
											{message.type === 'text' ? (
												message.value
											) : (
												<img
													className='img-thumbnail'
													src={message.value}
													width={250}
													height={250}
													alt=''
												/>
											)}
										</span>
									</p>
								</div>
								<div className='col-3 text-end'>
									<p className='m-0 text-muted fs-6'>
										{' '}
										{format(new Date(message.createdAt), 'MM/dd/yyyy')}
									</p>
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};
