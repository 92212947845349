import React, { useEffect, useState } from 'react';

import {
	getSubscriptionPlans,
	addSubscriptionPlan,
	deleteSubscriptionPlan,
	editSubscriptionPlan,
} from 'services/afric.service';

export const SubscriptionPlans = () => {
	const [plans, setPlans] = useState<any[]>([]);
	const [formData, setFormData] = useState<any>({
		name: '',
		interval: '',
		amount: '',
		description: '',
	});
	const [editId, setEditId] = useState('');

	const abortController = new AbortController();

	useEffect(() => {
		getSubscriptionPlans(abortController.signal).then((res: any) => {
			setPlans(res.data);
		});
	}, [abortController.signal]);

	const handleSubmit = (e: any) => {
		e.preventDefault();

		addSubscriptionPlan(abortController.signal, formData).then((res: any) => {
			window.alert(res.message);
			setFormData({});
		});
	};

	const handleDelete = (id: string) => {
		deleteSubscriptionPlan(abortController.signal, id)
			.then((res: any) => {
				window.alert(res.message);
			})
			.catch((err) => console.log(err));
	};

	const handleEdit = (id: string) => {
		editSubscriptionPlan(abortController.signal, id, formData)
			.then((res: any) => {
				window.alert(res.message);
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className='container mt-4'>
			<div className='card card-dash'>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col-8'>
							<h2 className='card-header-title'>Subscription Plans</h2>
						</div>
						<div className='col-4'>
							<div className='dropdown float-end'>
								<button
									className='btn btn-light'
									type='button'
									id='newUser'
									data-bs-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
								>
									New Plan
								</button>
								<form
									className='dropdown-menu dropdown-menu-end dropdown-menu-card mt-1'
									aria-labelledby='newUser'
									onSubmit={handleSubmit}
								>
									<div className='card-header'>
										<h4 className='card-header-title'>Add new Admin User</h4>
									</div>

									<div className='card-header'>
										<div className='row g-0 align-items-center'>
											<div className='col-3'>
												<label className='mb-0' htmlFor='addUserName'>
													Name
												</label>
											</div>
											<div className='col'>
												<input
													className='form-control form-control-flush'
													id='addUserName'
													type='text'
													placeholder='Plan Name'
													value={formData.name}
													onChange={(e) =>
														setFormData({
															...formData,
															name: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className='card-header'>
										<div className='row g-0 align-items-center'>
											<div className='col-3'>
												<label className='mb-0' htmlFor='addUserEmail'>
													Amount
												</label>
											</div>
											<div className='col'>
												<input
													className='form-control form-control-flush'
													id='addUserEmail'
													type='text'
													placeholder='Amount'
													value={formData.amount}
													onChange={(e) =>
														setFormData({ ...formData, amount: e.target.value })
													}
												/>
											</div>
										</div>
									</div>
									<div className='card-header'>
										<div className='row g-0 align-items-center'>
											<div className='col-3'>
												<label className='mb-0' htmlFor='addUserEmail'>
													Interval
												</label>
											</div>
											<div className='col'>
												<input
													className='form-control form-control-flush'
													id='addUserPhone'
													type='text'
													placeholder='Interval'
													value={formData.interval}
													onChange={(e) =>
														setFormData({
															...formData,
															interval: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className='card-header'>
										<div className='row g-0 align-items-center'>
											<div className='col-3'>
												<label className='mb-0' htmlFor='addUserEmail'>
													Desc.
												</label>
											</div>
											<div className='col'>
												<input
													className='form-control form-control-flush'
													id='addUserPassword'
													type='text'
													placeholder='Description'
													value={formData.description}
													onChange={(e) =>
														setFormData({
															...formData,
															description: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</div>
									<div className='card-footer'>
										<button
											className='btn w-100 btn-white bg-color-brand text-white'
											type='submit'
										>
											Add Plan
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-12'>
				<div className='row'>
					{plans.map((plan) => (
						<div className='col-6' key={plan._id}>
							<div className='card'>
								<div className='card-body'>
									<div className='list-group list-group-flush my-n3'>
										<div className='list-group-item'>
											<div className='row align-items-center'>
												<div className='col ms-n2'>
													<h3 className='mb-3  '>
														{plan.name} - N{plan.amount} {plan.interval}
													</h3>
													<p className='p-0  '>{plan.description}</p>
													<p className='p-0 text-secondary fw-bold  '>
														{plan.planCode}
													</p>
												</div>
												<div className='col-auto'>
													<div className='dropdown'>
														<a
															href='/#'
															className='dropdown-ellipses dropdown-toggle'
															role='button'
															data-bs-toggle='dropdown'
															aria-haspopup='true'
															aria-expanded='false'
														>
															<i className='fe fe-more-vertical'></i>
														</a>
														<div className='dropdown-menu dropdown-menu-end'>
															<a
																href='#!'
																className='dropdown-item'
																data-bs-toggle='modal'
																data-bs-target='#modalEdit'
																onClick={() => {
																	setEditId(plan._id);
																	setFormData({
																		name: plan.name,
																		interval: plan.interval,
																		amount: plan.amount,
																		description: plan.description,
																	});
																}}
															>
																Edit Plan
															</a>
															<button
																className='dropdown-item'
																onClick={() => handleDelete(plan._id)}
															>
																Delete Plan
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div
				className='modal fade'
				id='modalEdit'
				tabIndex={-1}
				role='dialog'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content'>
						<div className='modal-card card'>
							<div className='card-header'>
								<h2 className='card-header-title' id='exampleModalCenterTitle'>
									Edit Plan - {formData.name}
								</h2>

								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>

							<div className='card-body'>
								<div className='form-group'>
									<label className='form-label'>Name</label>
									<input
										type='text'
										className='form-control'
										defaultValue={formData.name}
										onChange={(e) =>
											setFormData({
												...formData,
												name: e.target.value,
											})
										}
									/>
								</div>

								<div className='form-group'>
									<label className='form-label'>Description</label>
									<input
										type='text'
										className='form-control'
										defaultValue={formData.description}
										onChange={(e) =>
											setFormData({
												...formData,
												description: e.target.value,
											})
										}
									/>
								</div>

								<div className='form-group'>
									<label className='form-label'>Amount</label>
									<input
										type='number'
										className='form-control'
										defaultValue={formData.amount}
										onChange={(e) =>
											setFormData({
												...formData,
												amount: e.target.value,
											})
										}
									/>
								</div>

								<div className='form-group'>
									<label className='form-label'>
										Interval - (daily, monthly, yearly)
									</label>
									<input
										type='text'
										className='form-control'
										defaultValue={formData.interval}
										onChange={(e) =>
											setFormData({
												...formData,
												interval: e.target.value,
											})
										}
									/>
								</div>

								<button
									className='btn btn-white bg-color-brand text-white'
									onClick={() => handleEdit(editId)}
								>
									Update
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
