import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
	getApprovedVendors,
	getUnapprovedVendors,
	approveVendor,
	disApproveVendor,
} from 'services/afric.service';

export const VendorManagement = () => {
	const [vendors, setVendors] = useState<any[]>([]);
	const [type, setType] = useState('approved');
	const [page, setPage] = useState(1);

	const abortController = new AbortController();

	useEffect(() => {
		if (type === 'approved') {
			getApprovedVendors(abortController.signal).then((res: any) => {
				setVendors(res.data);
			});
		}

		if (type === 'unapproved') {
			getUnapprovedVendors(abortController.signal).then((res: any) => {
				setVendors(res.data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const limit = 10;

	const lastIndex = page * limit;
	const firstIndex = lastIndex - limit;

	const currentVendors = vendors && vendors.slice(firstIndex, lastIndex);

	const nPages = Math.ceil(vendors && vendors.length / limit);

	return (
		<div className='container p-3'>
			<div className='card card-dash'>
				<h2 className='card-header'>Vendor Management</h2>
			</div>
			<div className='card'>
				<div className='card-body'>
					<div className='row'>
						<div className='col-4 p-3'>
							<label>Type</label>
							<select
								className='form-select my-2'
								data-choices='{"searchEnabled": false}'
								onChange={(e) => setType(e.target.value)}
								defaultValue={type}
							>
								<option value='approved'>Approved</option>
								<option value='unapproved'>Unapproved</option>
							</select>
						</div>
					</div>
					<div className='table-responsive mb-0'>
						<table className='table table-sm table-nowrap card-table  '>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{vendors &&
									currentVendors &&
									currentVendors.map((vendor) => (
										<tr>
											<td>{vendor.fullName}</td>
											<td>{vendor.email}</td>
											<td>{vendor.phone}</td>
											<td>
												{format(new Date(vendor.createdAt), 'MM/dd/yyyy')}
											</td>
											<td>
												{vendor.isVendorApproved ? (
													<a
														href='#!'
														className='btn btn-danger'
														onClick={() =>
															disApproveVendor(
																abortController.signal,
																vendor._id
															).then((res) => alert(res.message))
														}
													>
														Disapprove
													</a>
												) : (
													<a
														href='#!'
														className='btn btn-success'
														onClick={() =>
															approveVendor(
																abortController.signal,
																vendor._id
															).then((res) => alert(res.message))
														}
													>
														Approve
													</a>
												)}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<div className='col-12 mt-3'>
						<div className='d-flex flex-row justify-content-between'>
							{page > 1 && (
								<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-0 pe-4 border-end  '
											href='#!'
											onClick={() => {
												if (page !== 1) setPage(page - 1);
											}}
										>
											<i className='fe fe-arrow-left me-1'></i> Prev
										</a>
									</li>
								</ul>
							)}

							<ul className='list-pagination pagination pagination-tabs card-pagination'></ul>

							{currentVendors.length === limit && (
								<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
									<li className='page-item'>
										<a
											className='page-link ps-4 pe-0 border-start  '
											href='#!'
											onClick={() => {
												if (page !== nPages) setPage(page + 1);
											}}
										>
											Next <i className='fe fe-arrow-right ms-1'></i>
										</a>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
